html {
    //background-color: rgb(249 250 251);
    scroll-behavior: smooth;
    scroll-padding-top: 82px;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

// disable mui license markdown
.MuiDateRangeCalendar-root > div:nth-child(1) {
    display: none;
}
